import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import stars5 from '../../../images/reviews/five.svg'
import stars4 from '../../../images/reviews/four.svg'
import Link from '../../Link'
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, EmblaCarouselDotButton } from '../../panels/Button/EmblaCarouselDotButton'
import Autoplay from 'embla-carousel-autoplay'

const ShopButton = styled(Link)`
  text-transform: uppercase;
  object-fit: contain;
  font-family: Objectivity;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.01px;
  color: rgb(var(--black));
  margin-top: -32px;
  @media (max-width: 1025px) {
    margin: 16px 0;
  }
`
const SliderContainer = styled.div`
  overflow-x: hidden;
  max-width: 100vw;
`
const ReviewsSection = styled.div`
  background-color: rgb(var(--very-light-pink));
  min-height: 700px;
  padding: 64px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1025px) {
    padding: 32px;
    min-height: 850px;
  }
`
const ReviewsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  @media (max-width: 1025px) {
    flex-direction: column-reverse;
    flex-grow: 0;
  };
`
const ReviewImageContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1025px) {
    width: 100%;
  }
`
const ReviewImage = styled(GatsbyImage)`
  width: 484px;
  @media (max-width: 1025px) {
    width: 100%;
  }
`
const ReviewContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1025px) {
    width: 80%;
    padding: 16px;
  }
`
const ReviewContentContainer = styled.div`
  height: 484px;
  width: 425px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: 1025px) {
    width: auto;
    height: auto;
  }
`
const StarsImage = styled.img`
  @media (max-width: 1025px) {
    width: 35%;
    margin-top: 32px;
  }
`
const TopText = styled.div`
  text-transform: uppercase;
  object-fit: contain;
  font-family: Objectivity;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.71px;
  color: rgb(var(--terracota));
  @media (max-width: 1025px) {
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.09px;
    color: rgb(var(--dark-blue-grey));
  }
`
const ReviewText = styled.div`
  object-fit: contain;
  font-family: Objectivity;
  font-size: 31px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -0.91px;
  color: rgb(var(--black));
  margin: 24px 0;
  @media (max-width: 1025px) {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.59px;
    color: rgb(var(--black));
    margin: 12px 0;
  }
`
const ReviewUser = styled.div`
  object-fit: contain;
  font-family: Objectivity;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.78;
  letter-spacing: -0.53px;
  color: rgb(var(--terracota));
  @media (max-width: 1025px) {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.41px;
    color: rgb(var(--terracota));
  }
`
const StaticHeaderImage = styled.img`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    height: calc(100vh - 110px);
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }
  object-fit: cover;
`

const Embla = styled.div`
  overflow: hidden;
`

const EmblaContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
  min-width: 0;

  .embla__slide > div {
    width: 100vw;
  }
`

const EmblaControls = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.2rem;

  @media (min-width: 1028px), (max-width: 479px) {
    display: none;
  }
`;

const EmblaDots = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
`


const Reviews = ({ data }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    align: 'center',
    loop: true,
    inViewThreshold: 1,
  }, [
    Autoplay({ playOnInit: true, delay: 3000 })
  ]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = EmblaCarouselDotButton(emblaApi)

  return (
    <SliderContainer>
      <Embla ref={emblaRef}>
        <EmblaContainer >
          {data.ReviewItems.map((ReviewItems, key) => (
            <div key={key} className="embla__slide">
              <ReviewsSection>
                <ReviewsContainer>
                  <ReviewImageContainer>
                    {data.ReviewImage?.isStatic ? (
                      <StaticHeaderImage src={data.ReviewImage?.url} />
                    ) : (
                      <>
                        {
                          ReviewItems.ReviewImage?.file && (
                            <ReviewImage
                              image={withArtDirection(
                                getImage(ReviewItems.ReviewImage.file?.childImageSharp?.gatsbyImageData),
                                [
                                  {
                                    media: '(max-width: 1024px)',
                                    image: getImage(
                                      ReviewItems.ReviewImage.file?.childImageSharp?.gatsbyImageDataMobile
                                    ),
                                  },
                                ]
                              )}
                              alt={ReviewItems.ReviewImage?.alternativeText || ReviewItems.ReviewImage?.name}
                            />
                          )
                        }
                      </>
                    )}
                  </ReviewImageContainer>
                  <ReviewContent>
                    <ReviewContentContainer>
                      <TopText>RATED 4.9 STARS BY THOUSANDS OF HAPPY TRAVELLERS</TopText>
                      <div>
                        <StarsImage
                          loading="lazy"
                          src={ReviewItems.rating === '5' ? stars5 : stars4}
                          alt={ReviewItems?.alternativeText || ReviewItems?.name}
                        />
                        <ReviewText> “{ReviewItems.ReviewDescription}” </ReviewText>
                        <ReviewUser>
                          {' '}
                          <b>— {ReviewItems.CustomerName}</b>, {ReviewItems.ItemPurchased}{' '}
                        </ReviewUser>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                      </div>
                    </ReviewContentContainer>
                  </ReviewContent>
                </ReviewsContainer>
                <ShopButton to={ReviewItems.to}>
                  {' '}
                  SHOP THE {ReviewItems.ItemPurchased} {'>'}
                </ShopButton>
              </ReviewsSection>
            </div>
          ))}
        </EmblaContainer>
        <EmblaControls className="embla__controls">
          <EmblaDots className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={`${index === selectedIndex ? ' embla__dot--selected' : ''}`}
              />
            ))}
          </EmblaDots>
        </EmblaControls>
      </Embla>
    </SliderContainer>
  )
}

export default Reviews
